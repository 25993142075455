
import * as Sentry from '@sentry/browser';

let autocomplete;
let addressField;



function closeAlert(){
  elem.style.display = "none";
}

function initAutocomplete() {
    addressField = document.querySelector('#id_address');

    autocomplete = new google.maps.places.Autocomplete(addressField, {
        fields: ["address_components", "geometry"],
        types: ["address"],
    });

    autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
    const place = autocomplete.getPlace();
    let address = "";

    for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                document.querySelector("#id_address_2").value = component.long_name;
                break;
            }
            case "route": {
                addressField.value = component.short_name;
                break;
            }
            case "postal_code": {
                document.querySelector("#id_postal_code").value = component.long_name;
                break;
            }
            case "locality": {
                document.querySelector("#id_city").value = component.long_name;
                break;
            }
            case "country": {
                document.querySelector("#id_country").value = component.long_name;
                break;
            }
        }
    }

    document.querySelector("#id_latitude").value = place.geometry.location.lat();
    document.querySelector("#id_longitude").value = place.geometry.location.lng();
}

let elem = document.querySelector('.alert.dismissible');

if(elem){
  let closeIcon = "<span class='alert__close'>x</span>";
  elem.innerHTML = elem.innerHTML + closeIcon;

  document.querySelector('.alert__close').addEventListener("click", closeAlert, false);
}

(function(){
  // $('.analysis_parameters_form').submit(submit_analysis_parameters_form);

  var menu = $(".menu");

  function setup(){
    menuShow();
    modalMenu();
    setupSentryBrowserIntegration();
    setupBtnOrderColumns();
  }

  function setupSentryBrowserIntegration(){
    // Initialize Sentry to send metrics about the web vitals (first contentful paint, largest contentful paint, etc.)
    if (window.settings && window.settings.send_logs) {
      Sentry.init({
        dsn: 'https://4e7d4cf592ff4acbb1cd8acc38706597@o1159022.ingest.sentry.io/6242589',
        integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
        tracesSampleRate: window.settings.trace_sampler_rate,
        release: window.settings.app_version,
        environment: window.settings.logs_env_name,
      });
    }
  }

  function modalMenu(){
    $(".modal__link").click(function(event){
      event.preventDefault();
      let anchor = $(this).attr('href');


      $(".modal__item").removeClass("modal__item--current");
      $(this).parent().addClass("modal__item--current");

      $(".flow__part").removeClass("flow__part--visible");
      $(anchor).addClass("flow__part--visible");
    });
  }


  function menuShow(){
    $(".menu__action").click(function(event) {

      event.preventDefault();
      menu.toggleClass("menu--hidden");
      $(".menu__action").toggleClass("action--hide");

    });
  }

  function setupBtnOrderColumns(){
    $(".button--order-column").click(function(event){
      event.preventDefault();
      console.log("click");
      // Set the value of the order input
      let value = $(this).data("value");
      let input = $("#order_input");
      input.val(value);

      // Submit the form
      let form = $("#" + $(this).data("form"));
      form.submit();
    });
  }
  setup();
})();

// function submit_analysis_parameters_form() {
//   var isValid = false;
//   $.ajax({
//       data: $(this).serialize(), // get the form data
//       type: $(this).attr('method'),
//       url: "en/analysis/save_parameters",
//       // on success
//       success: function (response) {
//           alert(response.result)
//           isValid = true;
//       },
//       // on error
//       error: function (response) {
//           // alert the error if any error occured
//           alert(response.responseJSON.errors);
//           console.log(response.responseJSON.errors)
//       }
//   });
//   if (!isValid) {
//       event.preventDefault();
//   }
// }


export { initAutocomplete };
